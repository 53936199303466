import { MouseEvent } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useQuery } from 'react-query'
import {
  Anvil, Briefcase,
  Calendar, ChevronLeft, FileText,
  FolderTree, GraduationCap,
  Hammer, LayoutDashboard,
  LayoutPanelTop, LogOut,
  Package, Plane,
  Users, Users2
} from 'lucide-react'
import { PAGES_SLUGS } from '@/constants/pages'
import { fetchGetUserMe } from '@/lib/members'
import { useAuth } from '@/context/AuthContext'
import { useUser } from '@/context/UserProvider'
import { ButtonUI } from '@/shared/buttons/ButtonUI'
import { QUERY_KEYS } from '@/constants/query-keys'
import './Sidebar.css'

const menuItems = [
  { name: 'Dashboard', path: PAGES_SLUGS.dashboard, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_HR', 'ROLE_DOM', 'ROLE_CC'], icon: <LayoutDashboard /> }, // TODO:DONE
  { name: 'Jobs', path: PAGES_SLUGS.jobs, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_HR', 'ROLE_DOM', 'ROLE_CC'], icon: <Briefcase />  }, // TODO:DONE
  { name: 'Members', path: PAGES_SLUGS.members, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_HR', 'ROLE_DOM'], icon: <Users /> }, // TODO:DONE
  { name: 'Training Requests', path: PAGES_SLUGS.trainingRequests, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_HR', 'ROLE_DOM', 'ROLE_CC'], icon: <GraduationCap /> }, // TODO:DONE
  { name: 'Training Types', path: PAGES_SLUGS.trainingTypes, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_HR'], icon: <GraduationCap /> }, // TODO:DONE
  { name: 'D&A Program', path: PAGES_SLUGS.drugAlcohol, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_HR'], icon: <Calendar /> }, // TODO:DONE
  { name: 'Schedule', path: PAGES_SLUGS.schedule, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_HR', 'ROLE_DOM', 'ROLE_CC'], icon: <Package /> }, // TODO:DONE
  { name: 'Consumables', path: PAGES_SLUGS.consumables, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_DOM', 'ROLE_CC'], icon: <Anvil /> }, // TODO:DONE
  { name: 'Calibrated Tools', path: PAGES_SLUGS.calibratedTools, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_DOM', 'ROLE_CC'], icon: <Hammer /> },// TODO:DONE
  { name: 'Aircrafts', path: PAGES_SLUGS.aircrafts, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_DOM', 'ROLE_CC'], icon: <Plane /> }, // TODO:DONE
  { name: 'Job Categories', path: PAGES_SLUGS.categories, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_DOM'], icon: <FolderTree /> }, // TODO:DONE
  { name: 'Customers', path: PAGES_SLUGS.customers, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_HR', 'ROLE_DOM'], icon: <Users2 /> }, // TODO:DONE
  { name: 'PDF Templates', path: PAGES_SLUGS.pdfTemplates, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_HR', 'ROLE_DOM'], icon: <FileText /> },
  { name: 'PDF Requests', path: PAGES_SLUGS.pdfRequests, roles: ['ROLE_SA', 'ROLE_QA', 'ROLE_HR', 'ROLE_DOM'], icon: <LayoutPanelTop />}  // TODO:DONE
]

interface SidebarProps {
  mobileMenu: boolean
  sidebarExpanded: boolean
  toggleSidebar: () => void
  setMobileMenu: (toggle: boolean) => void
}

const Sidebar = ({ mobileMenu, sidebarExpanded, toggleSidebar, setMobileMenu }: SidebarProps) => {
  const { onLogout } = useAuth()
  const { role } = useUser()
  const location = useLocation()


  const { data: userMe } = useQuery(
    [QUERY_KEYS.userMe], fetchGetUserMe,
    { refetchOnWindowFocus: false, staleTime: Infinity }
  )

  const isLinkActive = (path) => {
    return location.pathname.startsWith(path)
  }

  const handleLogout = (e: MouseEvent) => {
    e.preventDefault()
    onLogout()
  }

  return (
    <aside
      className={`sidebar ${!sidebarExpanded ? 'short' : ''} ${mobileMenu ? 'mobile' : ''}`}
      tabIndex={0}
      onBlur={() => setMobileMenu(false)}
    >
      <ButtonUI
        className={`btn-toggle ${!sidebarExpanded && 'active'}`}
        onClick={toggleSidebar}
      >
        <ChevronLeft size={16} className={`duration-300 ${!sidebarExpanded && 'rotate-180'}`} />
      </ButtonUI>

      <nav className='sidebar-nav h-full py-[40px] flex-column justify-between relative scrollbar-hide '>
        <div className='flex-column'>
          {menuItems.map(item =>
            (!item.roles || (role && item.roles.includes(role))) &&
            <Link
              key={item.name}
              to={item.name === 'Dashboard' ? `${PAGES_SLUGS.dashboard}/${userMe?.id}` : item.path}
              onClick={() => setMobileMenu(false)}
              className={`sidebar-link
              ${(item.name === 'Consumables' || item.name === 'Customers') && 'mt-7'}
              ${isLinkActive(item.path) && 'active'}  `}
            >
              {sidebarExpanded && item.name}
              {!sidebarExpanded && item.icon}
              </Link>
          )}
        </div>
        <Link key='logout' onClick={handleLogout} to='' className='sidebar-link'>
          { sidebarExpanded ? 'Logout' : <LogOut />  }
        </Link>
      </nav>
    </aside>
  )
}

export default Sidebar
