import { createContext, useContext, useState, useEffect, ReactNode } from 'react'
import { useAuth } from './AuthContext'

interface IUserContext {
  role: string | null
  SA_QA_DOM: boolean
  SA_QA_HR_DOM: boolean
  SA_QA_HR_DOM_CC: boolean
  SA_HR: boolean
  SA_QA: boolean
  SA_HR_DOM: boolean
  SA_QA_HR: boolean
  SA_DOM: boolean
  isSuperAdmin: boolean
  isCrewChief: boolean
  isWorker: boolean
  isDOM: boolean
  isQA: boolean
  isHR: boolean
  isAuthenticated: boolean
}

const UserContext = createContext<IUserContext | undefined>(undefined)

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [role, setRole] = useState<string | null>(null)
  const { isAuth, roles, isAuthReady } = useAuth()

  useEffect(() => {
    if (isAuth && isAuthReady) {
      const actualRole = roles?.find((role: string) => role !== 'ROLE_USER') || null
      setRole(actualRole)
    }
  }, [isAuth, isAuthReady, roles])

  const isSuperAdmin = role === 'ROLE_SA'
  const isCrewChief = role === 'ROLE_CC'
  const isWorker = role === 'ROLE_WORKER'
  const isDOM = role === 'ROLE_DOM'
  const isQA = role === 'ROLE_QA'
  const isHR = role === 'ROLE_HR'

  const SA_QA_HR_DOM_CC = isSuperAdmin || isQA || isHR || isDOM || isCrewChief
  const SA_QA_HR_DOM = isSuperAdmin || isQA || isHR || isDOM
  const SA_QA_DOM = isSuperAdmin || isQA || isDOM
  const SA_QA_HR = isSuperAdmin || isQA || isHR
  const SA_HR_DOM = isSuperAdmin || isHR || isDOM
  const SA_DOM = isSuperAdmin || isDOM
  const SA_HR = isSuperAdmin || isHR
  const SA_QA = isSuperAdmin || isQA

  return (
    <UserContext.Provider
      value={{
        role,
        isQA,
        isHR,
        isDOM,
        isWorker,
        isCrewChief,
        isSuperAdmin,
        SA_DOM,
        SA_HR,
        SA_QA,
        SA_QA_HR,
        SA_HR_DOM,
        SA_QA_DOM,
        SA_QA_HR_DOM,
        SA_QA_HR_DOM_CC,
        isAuthenticated: isAuth
    }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}
