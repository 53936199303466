export const PAGES_SLUGS = {
  home: '/',
  dashboard: '/dashboard',
  dashboardId: '/dashboard/:id',
  editDashboard: '/dashboard/edit',
  editDashboardId: '/dashboard/edit/:id',

  // Jobs
  jobs: '/jobs',
  jobId: '/jobs/:id',
  addNewJob: '/jobs/add-new-job',
  editJob: '/jobs/edit-job',
  editJobId: '/jobs/edit-job/:id',

  // Members
  members: '/members',
  memberId: '/members/:id',
  addNewMember: '/members/add-new-member',
  editMember: '/members/edit-member',
  editMemberId: '/members/edit-member/:id',

  // Consumables
  consumables: '/consumables',

  // Calibrated tools
  calibratedTools: '/calibrated-tools',

  // Aircrafts
  aircrafts: '/aircrafts',

  // Categories
  categories: '/categories',

  // Customers
  customers: '/customers',
  customerId: '/customers/:id',
  addNewCustomer: '/customers/add-new-customer',
  editCustomer: '/customers/edit-customer',
  editCustomerId: '/customers/edit-customer/:id',

  // Recurrent Trainings
  trainingRequests: '/training-requests',
  trainingTypes: '/training-types',

  // Drug and Alcohol
  drugAlcohol: '/drug-alcohol',

  // Schedule
  schedule: '/schedule',
  scheduleId: '/schedule/:id',
  scheduleVacationRequests : '/schedule/vacation-requests',
  scheduleStatistics : '/schedule/statistics',

  // PDF Templates
  pdfTemplates: '/pdf-templates',
  pdfRequests: '/pdf-requests',

  // auth
  login: '/login',
  acceptInvite: '/accept-invite',
  resetPassword: '/reset-password',
  createPassword: '/create-password',
  logout: '/logout',
  // verifyPassword: '/accept-invite',
  verifyPassword: '/login/verify-password',

  settings: '/settings',

  chat: '/chat',

  accessDenied: '/access-denied',

  // not-found
  notFound: '*'
}

export const NON_AUTHORIZED_PAGES = [
  PAGES_SLUGS.login,
  PAGES_SLUGS.acceptInvite,
  PAGES_SLUGS.resetPassword,
  PAGES_SLUGS.createPassword,
  PAGES_SLUGS.verifyPassword,
  PAGES_SLUGS.jobId
]
