import axiosInstance from '../instance'
import Endpoints from '../endpoints'
import { ICreateMember, IMemberResponse, IMemberRole, IMemberStatus } from '@/types/members'
import { IJobResponse } from '@/types/jobs'

interface QueryParams {
  search?: string
  sort?: string
  order?: string
  role?: string
  userStatus?: string
  startDate?: string
  endDate?: string
  userId?: string
  page?: number
  itemsPerPage?: number
}

export const fetchGetAllMembers = async (params: QueryParams): Promise<IMemberResponse> => {
  try {
    const response = await axiosInstance.get(Endpoints.MEMBERS.MEMBERS, {
      params: {
        ...params,
        sort: params.sort,
        order: params.order
      }
    })

    return response.data
  } catch (error) {
    console.error('Error fetching members:', error)

    throw error
  }
}

export const fetchGetMemberAllStatuses = async (): Promise<IMemberStatus[]> => {
  try {
    const response = await axiosInstance.get(Endpoints.MEMBERS.STATUSES)

    return response.data
  } catch (error) {
    console.error('Error fetching statuses:', error)
    throw error
  }
}

export const fetchGetMemberAllRoles = async (): Promise<IMemberRole[]> => {
  try {
    const response = await axiosInstance.get(Endpoints.MEMBERS.ROLES)

    return response.data
  } catch (error) {
    console.error('Error fetching roles:', error)
    throw error
  }
}

export const fetchGetMemberById = async (id: string) => {
  try {
    const response = await axiosInstance.get(`${Endpoints.MEMBERS.MEMBERS}/${id}`)

    return response.data
  } catch (error) {
    console.error('Error fetching member:', error)

    throw error
  }
}

export const fetchCreateMember = async (options: ICreateMember) => {
  try {
    const response = await axiosInstance.post(`${Endpoints.MEMBERS.CREATE_MEMBER}`, options)

    return response.data
  } catch (error) {
    console.error('Error fetching create member:', error)

    return error
  }
}

export const fetchUpdateMember = async (id: string, options) => {
  try {
    const response = await axiosInstance.patch(`${Endpoints.MEMBERS.MEMBERS}/${id}`, options)

    return response.data
  } catch (error) {
    console.error('Error fetching update member:', error)

    return error
  }
}

export const fetchSendInviteMemberId = async (id: string) => {
  try {
    const response = await axiosInstance.post(`${Endpoints.MEMBERS.INVITE_MEMBER}/${id}`)

    return response.data
  } catch (error) {
    console.error('Error fetching create invite member:', error)

    throw error
  }
}

export interface IMembersNames {
  id: number
  firstName: string
  lastName: string
  roles: string[]
}

export const fetchGetAllMembersNames = async (): Promise<IMembersNames[]> => {
  try {
    const response = await axiosInstance.get(Endpoints.MEMBERS.NAMES)

    return response.data
  } catch (error) {
    console.error('Error fetching names:', error)
    throw error
  }
}

// Member all jobs
export const fetchGetMemberJobsById = async (params: QueryParams): Promise<IJobResponse> => {
  try {
    const response = await axiosInstance.get(Endpoints.JOBS.JOBS, {
      params: {
        ...params,
        page: params.page ?? 1,
        itemsPerPage: params.itemsPerPage ?? 10
      }
    })

    return response.data
  } catch (error) {
    console.error('Error fetching member jobs:', error)
    throw error
  }
}

// Current user (authorized)
export const fetchGetUserMe = async (): Promise<any> => {
  try {
    const response = await axiosInstance.get(Endpoints.MEMBERS.MEMBER_ME)

    return response.data
  } catch (error) {
    console.error('Error fetching user me:', error)

    throw error
  }
}
