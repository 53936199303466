import { Toaster } from 'sonner'
import RootLayout from '@/components/layouts/RootLayout'
import { AuthProvider } from '@/context/AuthContext'
import { GeneralProvider } from '@/context/GeneralContext'
import { UserProvider } from '@/context/UserProvider'
import { PageWrapper } from '@/components/layouts/PageWrapper'

const App = () => (
  <GeneralProvider>
    <AuthProvider>
      <UserProvider>
        <PageWrapper>
          <RootLayout />
          <Toaster richColors />
        </PageWrapper>
      </UserProvider>
    </AuthProvider>
  </GeneralProvider>
)

export default App
