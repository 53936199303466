const Endpoints = {
  AUTH: {
    LOGIN_ADMIN: 'login-admin',
    LOGIN: 'login',
    RESET_PASSWORD: 'reset_password',
    CREATE_PASSWORD: 'reset_password/reset',
    TOKEN_REFRESH: 'token/refresh',
    LOGOUT: 'token/invalidate',
  },
  MEMBERS: {
    MEMBERS: 'users',
    MEMBER_ME: 'users/me',
    CREATE_MEMBER: 'users/create',
    INVITE_MEMBER: 'users/invite',
    STATUSES: 'users/statuses',
    ROLES: 'users/roles',
    NAMES: 'users/names',
  },
  JOBS: {
    JOBS: 'jobs',
    STATUSES: 'jobs/statuses'
  },
  TASKS: {
    TASKS: 'tasks',
  },
  OJTS: {
    OJTS: 'ojts',
    OJT_HOURS: 'ojt-hours',
  },
  CONSUMABLES: {
    CONSUMABLES: 'consumables',
  },
  TESTS: {
    TESTS: 'tests',
    STATUS: 'status',
  },
  SCHEDULE: {
    SCHEDULE: 'schedule',
  },
  PDF: {
    PDF_TEMPLATES: 'pdf-templates',
    DOCUMENT_REQUESTS: 'document-requests'
  },
  VACATIONS: {
    VACATIONS: 'vacations',
  },
  TRAININGS: {
    TRAININGS: 'trainings',
    TRAININGS_STATUS: 'status',
    TRAINING_TYPE: 'training-types',
  },
  FILES: {
    FILES: 'files'
  },
  LIBRARY: {
    LIBRARY_AUTH: 'authorizations',
    LIBRARY_MANUFACTURERS: 'manufacturers',
  },
  CALIBRATED_TOOLS: {
    TOOLS: 'tools'
  },
  CATEGORIES: {
    CATEGORY: 'job-category',
  },
  CUSTOMERS: {
    CUSTOMERS: 'customers',
    CUSTOMERS_NAMES: 'names',
  },
  CHATS: {
    CHATS: 'chats',
    MERCURE_JWT: 'mercure-jwt',
    READ: 'read',
    DELIVERED: 'delivered',
    UNREAD: 'unread',
  }
}

export default Endpoints
