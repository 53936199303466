import { useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { NON_AUTHORIZED_PAGES } from '@/constants/pages'
import { useLocalStorage } from '@/hooks'
import Header from './header/Header'
import Sidebar from './sidebar/Sidebar'

const SIDEBAR_STATE_KEY = 'sidebarState'

const RootLayout = () => {
  const [mobileMenu, setMobileMenu] = useState<boolean>(false)
  const location = useLocation()
  const { getStorageValue, setStorageValue } = useLocalStorage()
  const [sidebarExpanded, setSidebarExpanded] = useState<boolean>(() => {
    const storedValue = getStorageValue(SIDEBAR_STATE_KEY)
    return storedValue ? JSON.parse(storedValue) : true
  })

  const isLogin = NON_AUTHORIZED_PAGES.includes(location.pathname)

  const toggleMenu = () => setMobileMenu(!mobileMenu)

  const toggleSidebar = () => {
    const newSidebarState = !sidebarExpanded
    setSidebarExpanded(newSidebarState)
    setStorageValue(SIDEBAR_STATE_KEY, JSON.stringify(newSidebarState))
  }

  return (
    <div className='flex flex-col h-screen'>
      <Header
        mobileMenu={mobileMenu}
        toggleMenu={toggleMenu}
        isLogin={isLogin}
      />

      <div className='relative flex flex-1 overflow-x-hidden'>
        {!isLogin &&
          <Sidebar
            sidebarExpanded={sidebarExpanded}
            mobileMenu={mobileMenu}
            setMobileMenu={setMobileMenu}
            toggleSidebar={toggleSidebar}
          />
        }

        <main className='flex-1 h-full bg-light-100 overflow-x-auto'>
          <Outlet />
        </main>
      </div>
    </div>
  )
}

export default RootLayout
