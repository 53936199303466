import { useLocation, useNavigate } from 'react-router-dom'
import { Mail, Menu, MessageCircle, Settings, X } from 'lucide-react'
import { PAGES_SLUGS } from '@/constants/pages'
import { TooltipUI } from '@/components'

interface NavigationProps {
  mobileMenu: boolean
  toggleMenu: () => void
  isLogin: boolean
}

const Navigation = ({ mobileMenu, toggleMenu, isLogin }: NavigationProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const chatPage = location.pathname.includes(PAGES_SLUGS.chat)

  return (
    <nav className='w-full flex-space-between'>
      <div className='flex-center-y font-medium'></div>
      {
        !isLogin &&
          <div className='flex-center-y gap-6 md:gap-10 relative'>
            <TooltipUI
              content='Chat'
              trigger={
                <MessageCircle
                  size={18}
                  onClick={() => navigate(PAGES_SLUGS.chat)}
                  className={`hover:stroke-primary cursor-pointer duration-300 ${chatPage && 'stroke-primary'}`}
                />
              }
            />
            <Mail
              size={18}
              className='hover:stroke-primary cursor-pointer duration-300'
            />
            <TooltipUI
              className='absolute z-[100] top-0 right-5'
              content='Account settings'
              trigger={
                <Settings
                  size={18}
                  className='hover:stroke-primary cursor-pointer duration-300'
                  onClick={() => navigate(PAGES_SLUGS.settings)}
                />
              }
            />
            <span onClick={toggleMenu} className='block lg:hidden'>
              { mobileMenu ? <X /> : <Menu /> }
            </span>
          </div>
      }
    </nav>
  )
}

export default Navigation
