/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useNavigate } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import { ILoginData, TChangePassword } from '@/types/auth'
import { PAGES_SLUGS } from '@/constants/pages'
import { useLocalStorage } from '@/hooks/useLocalStorage'
import { useGeneral } from './GeneralContext'
import {
  createPasswordAPI,
  login,
  logout,
  refreshTokenAPI,
  resetPasswordAPI
} from '@/lib/auth'
import Endpoints from '@/lib/endpoints'
import axiosInstance from '@/lib/instance'

export interface IAuthContextProps {
  isAuth: boolean
  isAuthReady: boolean
  onLogin: (credentials: ILoginData, target: string) => Promise<void>
  onLogout: VoidFunction
  resetPassword: (email: string) => void
  createPassword: (passwords: TChangePassword, token: string) => void
  roles: string[]
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps)

interface IAuthProviderProps {
  children: ReactNode
}
export const AuthProvider: FC<IAuthProviderProps> = ({ children }) => {
  const { fetchData } = useGeneral()
  const { getStorageValue, setStorageValue, removeStorageValue } =
    useLocalStorage()
  const savedAu = getStorageValue<boolean>('isAuth')
  const [isAuth, setIsAuth] = useState<boolean>(
    savedAu ? Boolean(savedAu) : false
  )
  const [roles, setRoles] = useState<string[]>([])
  const [isAuthReady, setIsAuthReady] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    const token = getStorageValue<string>('token')
    if (token) {
      const decodedToken: any = jwtDecode(token)
      setRoles(decodedToken?.roles || []) // Зберігаємо ролі з токена
    }
    setIsAuthReady(true)
    setIsAuth(!!savedAu)
  }, [])

  useEffect(() => {
    axiosInstance.interceptors.response.use(
      (config) => config,
      async (error) => {
        const originalRequest = error.config

        if (
          error.response &&
          error.response.status === 401 &&
          error.config &&
          !error.config._isRetry &&
          !error.request.responseURL.includes(Endpoints.AUTH.TOKEN_REFRESH) &&
          !error.request.responseURL.includes(Endpoints.AUTH.LOGOUT)
        ) {
          originalRequest._isRetry = true

          try {
            const { data } = await refreshTokenAPI()
            setStorageValue('token', data.token)
            setStorageValue('refreshToken', data.refreshToken)

            return await axiosInstance.request(originalRequest)
          } catch (e) {
            removeStorageValue('token')
            removeStorageValue('refreshToken')
            navigate(PAGES_SLUGS.login)
            setIsAuth(() => false)
            await logout()

            return Promise.reject(
              new Error(
                'Session timed out. Please logout and log in to the system again.'
              )
            )
          }
        } else {
          if (error.response && error.response.data) {
            return Promise.reject(error.response.data)
          } else {
            removeStorageValue('token')
            removeStorageValue('refreshToken')
            navigate(PAGES_SLUGS.login)
            setIsAuth(() => false)
          }
        }
        throw error
      }
    )
  }, [])

  // call this function when you want to authenticate the user
  const onLogin = async (credentials: ILoginData, target: string) => {
    try {
      const data = await fetchData(login)<ILoginData>(credentials)
      setStorageValue('token', data.token)
      setStorageValue('refreshToken', data.refreshToken)

      // Декодуємо токен для отримання ролей
      const decodedToken: any = jwtDecode(data.token)
      console.log('onLogin', decodedToken)
      setRoles(decodedToken?.roles || [])

      navigate(target)
      setStorageValue('isAuth', 'true')
      setIsAuth(() => true)
      setIsAuthReady(true)
    } catch (error) {
      console.log('Failed to login', error)
      if (error instanceof Error) {
        if (error.message === 'Invalid Credentials') {
          throw error  // Прокидаємо помилку "Invalid Credentials" далі
        }
        throw new Error(error.message || 'An error occurred')
      }
      throw new Error('An unexpected error occurred')
    }
  }

  const resetPassword = async (email: string) => {
    await fetchData(resetPasswordAPI)<string>(email)
  }

  const createPassword = async (passwords: TChangePassword, token: string) => {
    await fetchData(createPasswordAPI)<{
      passwords: TChangePassword
      token: string
    }>({ passwords, token })
  }

  // call this function to sign out logged-in user
  const onLogout = async () => {
    removeStorageValue('token')
    removeStorageValue('isAuth')
    removeStorageValue('refreshToken')
    setIsAuth(() => false)
    setRoles([])
    // await fetchData(logout)(undefined)
    navigate(PAGES_SLUGS.login)
  }

  const value: IAuthContextProps = useMemo(() => {
    return {
      isAuth,
      isAuthReady,
      onLogin,
      resetPassword,
      createPassword,
      onLogout,
      roles
    }
  }, [isAuth, isAuthReady])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
