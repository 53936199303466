import { Navigate, Outlet } from 'react-router-dom'
import { useUser } from '@/context/UserProvider'
import { PAGES_SLUGS } from '@/constants/pages'

const ProtectedRoute = ({ allowedRoles }: { allowedRoles: string[] }) => {
  const { role, isAuthenticated } = useUser()

  if (!role) return <h2>Loading..</h2>

  // Якщо користувач не аутентифікований або не має дозволеної ролі
  if (!isAuthenticated || !allowedRoles.includes(role)) {
    return <Navigate to={PAGES_SLUGS.accessDenied} replace /> // Перенаправляємо на сторінку "Access Denied"
  }

  // Якщо користувач має потрібну роль, показуємо контент
  return <Outlet />
}

export default ProtectedRoute
