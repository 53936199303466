import Logo from './components/Logo'
import Navigation from './components/Navigation'

const Header = ({ mobileMenu, toggleMenu, isLogin }: { mobileMenu: boolean, toggleMenu: () => void, isLogin: boolean }) => {
  return (
    <header className='py-[5px] px-[16px] flex-center-y bg-dark-default text-white shadow-lg z-50 md:py-[22px] md:px-[40px]'>
      <div>
        <Logo />
      </div>
      <Navigation
        mobileMenu={mobileMenu}
        toggleMenu={toggleMenu}
        isLogin={isLogin}
      />
    </header>
  )
}

export default Header
